<template>
    <vs-row style="height:calc(90vh - 100px); width: 100%;">
        <flipbook class="flipbook" :pages="pagesPreviews" v-slot="flipbook" ref="flipbook" :ambient="1" :clickToZoom="false" @flip-right-end="pageFlipped" @flip-left-end="pageFlipped" :dragToFlip="false" >

            <vs-row justify="center" align="center" style="gap: 50px; margin-bottom: 50px;">
                
                <vs-button @click="$router.go(-1)">
                    <i class='bx bx-cart-alt bx-sm'></i> I miei ordini
                </vs-button>
                <vs-button circle icon :disabled="!flipbook.canFlipLeft" @click="flipbook.flipLeft"><i class='bx bx-chevron-left bx-sm'></i></vs-button>
                <span class="page-num">
                    Pagina {{ flipbook.page }} di {{ flipbook.numPages }}
                </span>
                <vs-button circle icon :disabled="!flipbook.canFlipRight" @click="flipbook.flipRight"><i class='bx bx-chevron-right bx-sm'></i></vs-button>
                <vs-button @click="downloadOrderFile" icon >
                    <i class='bx bx-download bx-sm'></i> Download
                </vs-button>

            </vs-row>

        </flipbook>
    </vs-row>
    </template>
    
        
    <script>

import {
    apiCall,
    baseUrl,
    
} from '../client';
import Flipbook from '../components/flipbook.es.js'

    export default ({

        components: {
            Flipbook
        },

        data() {
            return {
                pagesPreviews: []
            }
        },
        async mounted() {
            var result = await apiCall('GET', '/Order/' + this.$route.params.orderId + '/Preview');
            if (result.status == 200) {
                this.pagesPreviews = [null].concat(result.data.map(e => baseUrl + '/Order/' + this.$route.params.orderId + '/Preview/' + e))
                console.log(this.pagesPreviews)
            }  
        },

        methods: {
            pageFlipped(){

            },

            downloadOrderFile() {
                window.open(baseUrl + '/Order/' + this.$route.params.orderId + '/Download', "_blank");
            },


        }
    
    })
    </script>
    <style>
.flipbook {
    width: 100%;
    height: calc(80vh - 100px);
}

.flipbook-container {
    margin-bottom: 100px;
}

.flipbook .bounding-box {
    box-shadow: 0 0 20px rgb(145, 145, 145);
}
</style>